import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function Backstory({ data }) {
  return (
    <div className="side">
      <Img
        className="profilePic"
        fluid={data.about.childImageSharp.fluid}
        alt="Kashif"
      />
      <h2>Hi, Kashif Here</h2>
      <p>
        This site belongs to Kashif, a digital designer and developer in NY who
        produces the softest of wares with youthful energy and romantic ideals.
      </p>
    </div>
  )
}

const personalPic = () => (
  <StaticQuery
    query={graphql`
      {
        about: file(relativePath: { eq: "assets/images/about.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Backstory data={data} />}
  />
)

export default personalPic
