import React from "react"
import TileList from "../components/tilelist"
import SubnavList from "../components/subnavList"
import Backstory from "../components/backstory"

const aboutContent = () => {
  return (
    <div className="about">
      <div className="contentArea post">
        <Backstory />
        <div className="largeContent">
          <TileList />
        </div>
      </div>
      <SubnavList />
    </div>
  )
}

export default aboutContent;

